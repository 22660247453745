import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import validator from "validator";
import Select from "react-select";
import axios from "axios";

import ReCAPTCHA from "react-google-recaptcha";

import { toast } from "react-toastify";
import { useMessage } from "../../../services/hooks/useMessage";
import { reCapchaKey } from "../../../services/data/key";
import { AppContext } from "../../../services/hooks/Contex";
import { NoAvatar } from "../../../img";
import { CloseEyeIcon, OpenEyeIcon } from "../../Icons/Icons";

export default function RegistrationComponent({ request }) {
  const [img, setImg] = useState("");
  ///
  const emailRef = React.useRef(null);
  const [reCapcha, setReCapcha] = useState(null);
  const [reCapchaError, setReCapchaError] = useState(false);
  const [isButtonImg, setIsButtonImg] = React.useState(false);
  const [emailError, setEmailError] = useState(false);
  const [gender, setGender] = React.useState([]);
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const [isLoadGetCode, setLoadGetCode] = React.useState(false);
  const [form, setForm] = React.useState({
    surname: "",
    name: "",
    fatherland: "",
    dateBirth: "",
    passport: "",
    datePassport: "",
    email: "",
    code: "",
    password: "",
    passwordRep: "",
    tel: "",
    img: "",
  });
  const lang = localStorage.getItem("lang");

  const [formGender, setFormGender] = React.useState("");
  const [formCountry, setFormCountry] = React.useState("");

  const { country } = useSelector((state) => state.countryReducer);
  const { setActiveLinkNavbar } = React.useContext(AppContext);
  const message = useMessage();
  const { t } = useTranslation();

  const onClickToEmail = () => {
    emailRef.current.focus();
  };

  const change = React.useCallback(
    (e) => {
      if (e.target.name === "passport") {
        let input = e.target.value
          ?.split(" ")
          ?.filter((a) => a != "")
          ?.join("");
        return setForm({
          ...form,
          [e.target.name]: input.toUpperCase(),
        });
      }
      if (e.target.name === "email") {
        let input = e.target.value
          ?.split(" ")
          ?.filter((a) => a != "")
          ?.join("");
        input = input.replace(/[А-Яа-яЁё]/, "");
        if (input) {
          if (validator.isEmail(input)) {
            setEmailError(false);
          } else {
            setEmailError(true);
          }
        } else {
          setEmailError(false);
        }
        return setForm({
          ...form,
          [e.target.name]: input,
        });
      }
      // let input = e.target.value?.split(' ')?.filter(a => a != '')?.join('')
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form]
  );

  const onChange = (value) => {
    setReCapcha(value);
  };

  const getCodeForEmail = async () => {
    if (!form.email.length) {
      onClickToEmail();
      return message(t("register.enterEmail"), "warning");
    }
    if (emailError) {
      onClickToEmail();
      return message(t("register.mailError"), "warning");
    }

    setLoadGetCode(true);
    await axios
      .post("/api/site/email/verification", {
        lang,
        email: form.email,
      })
      .then((response) => {
        const messageResponse = response?.data?.message;
        return message(
          messageResponse ? messageResponse : t("success"),
          "success"
        );
      })
      .catch((error) => {
        const messageVerify = error?.response?.data?.message;
        return message(
          messageVerify ? messageVerify : t("somethingWrong"),
          "error"
        );
      })
      .finally(() => {
        setTimeout(() => {
          setLoadGetCode(false);
        }, 1500);
      });
  };

  const getDataGender = React.useCallback(async () => {
    try {
      const res = await request("/gender", "post", {
        id_lan: lang === "ru" ? 1 : 2,
      });
      setGender((pre) => (pre = res));
    } catch (e) {}
  }, [lang, country]);

  React.useEffect(() => {
    getDataGender();
    setActiveLinkNavbar(5);
  }, [lang]);

  const changeImg = (e) => {
    setIsButtonImg((pre) => (pre = true));
    setForm({ ...form, img: e.target.files[0] });
    if (e.target.files.length) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        setImg(reader.result);
      };
    }
  };

  const removeImg = () => {
    document.querySelector("#img").value = "";
    setIsButtonImg((pre) => (pre = false));
    setForm({ ...form, img: "" });
    setImg("");
  };

  const register = async (e) => {
    try {
      e.preventDefault();
      if (reCapcha) {
        if (form.email.length && emailError) {
          toast.error(t("register.mailError"));
        }
        if (form.password !== form.passwordRep)
          return message(t("register.passwordErr"), "error");
        if (
          form.surname.length &&
          form.name.length &&
          form.dateBirth.length &&
          form.password.length &&
          form.passwordRep.length &&
          form.datePassport.length &&
          form.email.length &&
          form.code.length &&
          formGender &&
          formCountry &&
          !emailError
        ) {
          setReCapchaError(false);
          let body = {
            surname: form.surname.trim(),
            name: form.name.trim(),
            fatherland: form.fatherland.length ? form.fatherland.trim() : "",
            dateBirth: form.dateBirth.trim(),
            gender: formGender.value,
            passport: form.passport.trim(),
            datePassport: form.datePassport.trim(),
            country: formCountry.value,
            email: form.email.trim(),
            code: form.code,
            password: form.password,
            tel: form.tel.length ? form.tel.trim() : "",
            img: form.img ? form.img : false,
            lang,
          };
          const dataForm = new FormData();
          Object.keys(body).map((item) =>
            dataForm.append(String(item), body[item])
          );

          // const {data} = await axios({  method: "post", url: basesUrl +'/register', data: dataForm,  headers:  { "Content-Type": "multipart/form-data" }})
          const response = await request("/register", "post", dataForm, {
            "Content-Type": "multipart/form-data",
          });
          console.log(response, "response");
          if (response.data.register) {
            message(response.message, "success");
            return setTimeout(() => {
              return window.location.replace(
                "https://edugate.edu.gov.kg/applicant/login"
              );
            }, 1500);
          }
        }
        return message(t("register.requiredErr"), "error");
      }
      setReCapchaError(true);
    } catch (e) {}
  };
  return (
    <div>
      <h1 className="animation_register">{t("register.mainText")}</h1>
      <form onSubmit={register}>
        <div className="form_register">
          <div className="box_register animation_register">
            <div className="register_fio_wrapper" style={{ width: "100%" }}>
              <div className="item_register">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label htmlFor="surname" className="title_register">
                    {t("register.surname")}
                  </label>
                  <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    required="required"
                    id="surname"
                    name="surname"
                    onChange={change}
                    value={form.surname}
                    className="input"
                  />
                </div>
              </div>

              <div className="item_register">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label htmlFor="name" className="title_register">
                    {t("register.name")}
                  </label>
                  <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    required="required"
                    id="name"
                    name="name"
                    onChange={change}
                    value={form.name}
                    className="input"
                  />
                </div>
              </div>

              <div className="item_register">
                <label htmlFor="fatherland" className="title_register">
                  {t("register.fatherland")}
                </label>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    required
                    id="fatherland"
                    name="fatherland"
                    onChange={change}
                    value={form.fatherland}
                    className="input"
                  />
                </div>
              </div>
            </div>

            <div className="register_image_upload">
              <div className="title_register">
                <span style={{ marginLeft: "2px" }}>{t("register.photo")}</span>
                <div className="container_img">
                  <img alt="avatar" src={img.length ? img : NoAvatar} />
                </div>
              </div>
              <div className="img_input">
                {isButtonImg ? (
                  <button type="button" onClick={removeImg}>
                    {t("register.removePhoto")}
                  </button>
                ) : (
                  <label htmlFor="img">{t("register.UploadPhoto")}</label>
                )}
                <input
                  accept="image/*"
                  id="img"
                  type="file"
                  onChange={changeImg}
                />
              </div>
            </div>
          </div>

          <div className="box_register animation_register">
            <div className="item_register">
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <label htmlFor="dateBirth" className="title_register">
                  {t("register.dateBirth")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="date"
                  required="required"
                  id="dateBirth"
                  name="dateBirth"
                  onChange={change}
                  value={form.dateBirth}
                  className="input"
                />
              </div>
            </div>

            <div className="item_register">
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <label htmlFor="gender" className="title_register">
                  {t("register.gender")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
              </div>
              <div style={{ width: "100%" }}>
                <Select
                  className="Select"
                  classNamePrefix="my_select"
                  name="gender"
                  id="gender"
                  options={gender?.map((item) => ({
                    value: item.id_gender,
                    label: item.gender,
                  }))}
                  placeholder={t("register.chooseGender")}
                  onChange={setFormGender}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>

          <div className="box_register animation_register">
            <div className="item_register">
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <label htmlFor="passport" className="title_register">
                  {t("register.passport")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  required="required"
                  id="passport"
                  name="passport"
                  onChange={change}
                  value={form.passport}
                  className="input"
                />
              </div>
            </div>

            <div className="item_register">
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <label htmlFor="datePassport" className="title_register">
                  {t("register.datePassport")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="date"
                  required="required"
                  id="datePassport"
                  name="datePassport"
                  onChange={change}
                  value={form.datePassport}
                  className="input"
                />
              </div>
            </div>
          </div>

          <div className="box_register animation_register">
            <div className="item_register">
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <label htmlFor="country" className="title_register">
                  {t("register.country")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
              </div>
              <div style={{ width: "100%" }}>
                <Select
                  name="country"
                  id="country"
                  className="Select"
                  classNamePrefix="my_select"
                  placeholder={t("register.chooseCountry")}
                  options={country?.map((item) => ({
                    value: item.id_country,
                    label: item.country,
                  }))}
                  onChange={setFormCountry}
                />
              </div>
            </div>
            <div className="item_register">
              <label htmlFor="tel" className="title_register">
                {t("register.tel")}
              </label>
              <div style={{ width: "100%" }}>
                <input
                  type="tel"
                  required="required"
                  id="tel"
                  name="tel"
                  onChange={change}
                  value={form.tel}
                  className="input"
                />
              </div>
            </div>
          </div>

          <div className="box_register animation_register">
            <div className="item_register">
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <label htmlFor="email" className="title_register">
                  Email/{t("register.login")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
                {emailError && (
                  <span
                    style={{
                      color: "#EF5466",
                      marginLeft: 10,
                      fontWeight: 400,
                    }}
                  >
                    {" "}
                    {t("register.mailError")} !
                  </span>
                )}
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="email"
                  required="required"
                  id="email"
                  name="email"
                  autoComplete="off"
                  onChange={change}
                  value={form.email}
                  ref={emailRef}
                  className="input"
                />
              </div>
            </div>

            <div className="item_register">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  opacity: !form.email.length || emailError ? "0.7" : "",
                }}
              >
                <label
                  htmlFor={!form.email.length || emailError ? "email" : "code"}
                  className="title_register"
                >
                  {t("register.code")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
              </div>
              <div style={{ width: "100%", display: "flex" }}>
                <input
                  type="text"
                  required="required"
                  id="code"
                  name="code"
                  autoComplete="off"
                  onChange={change}
                  value={form.code}
                  className="input input-placeholder"
                  disabled={!form.email.length || emailError}
                />
                <button
                  className={`get-code ${isLoadGetCode && "loading"}`}
                  onClick={getCodeForEmail}
                  type="button"
                  disabled={isLoadGetCode}
                  id="login-btn"
                >
                  {t("register.getCode")}
                </button>
              </div>
            </div>
          </div>

          <div className="box_register animation_register">
            <div className="item_register">
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <label htmlFor="password" className="title_register">
                  {t("register.password")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <input
                  type={visiblePassword ? "text" : "password"}
                  required="required"
                  id="password"
                  name="password"
                  onChange={change}
                  value={form.password}
                  className="input"
                />
                {visiblePassword ? (
                  <OpenEyeIcon onClick={setVisiblePassword.bind(null, false)} />
                ) : (
                  <CloseEyeIcon onClick={setVisiblePassword.bind(null, true)} />
                )}
              </div>
            </div>

            <div className="item_register">
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <label htmlFor="password" className="title_register">
                  {t("register.passwordRepeat")}
                </label>
                <span style={{ color: "#EF7F1A", display: "block" }}> *</span>
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <input
                  type={visiblePassword ? "text" : "password"}
                  required="required"
                  id="passwordRep"
                  name="passwordRep"
                  onChange={change}
                  value={form.passwordRep}
                  className="input"
                />
                {visiblePassword ? (
                  <OpenEyeIcon onClick={setVisiblePassword.bind(null, false)} />
                ) : (
                  <CloseEyeIcon onClick={setVisiblePassword.bind(null, true)} />
                )}
              </div>
            </div>
          </div>

          <div className="item_register_container">
            <a href="/site/dock/RegistrationInstruction.pdf" target="_top">
              <p>{t("register.examplePadding")}</p>
            </a>
            <div className="container_captcha">
              <span style={reCapchaError ? { border: "1px solid red" } : null}>
                <ReCAPTCHA
                  hl={lang}
                  sitekey={reCapchaKey}
                  onChange={onChange}
                />
              </span>
            </div>
          </div>

          <div className="footer_register_container animation_register">
            <a href="https://edugate.edu.gov.kg/applicant/login/">
              <div className="link_auth">{t("register.auth")}</div>
            </a>
            <button
              className="btn_register"
              type="submit"
              onClick={register}
              // disabled={
              //   !(
              //     form.surname.length &&
              //     form.name.length &&
              //     form.dateBirth.length &&
              //     form.password.length &&
              //     form.datePassport.length &&
              //     form.email.length &&
              //     formGender &&
              //     formCountry
              //   )
              // }
            >
              {t("register.btnRegister")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
