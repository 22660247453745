import React from 'react';
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Footer, Navbar } from "./components";
import RouterPage from "./pages/Router";
import { useHttp } from "./services/hooks/useHttp";
import { AppContext } from "./services/hooks/Contex";
import { countryAction } from "./redux/actions/countryAction";
import Loader from "./components/Loader/Loader";
import './styles/Inputs.css';
import { setCookie } from './utils.js/cookie';

const App = React.memo(() => {
    const [allUniversities, setAllUniversities] = React.useState([])
    const [defaultUniversities, setDefaultUniversities] = React.useState([])
    const [universities, setUniversities] = React.useState([])
    const [regions, setRegions] = React.useState([])
    const [activeLinkNavbar, setActiveLinkNavbar] = React.useState()
    const [changeSelect, setChangeSelect] = React.useState(null)
    const refUp = React.useRef()
    const [windowLoader, setWindowLoader] = React.useState(true)
    const { request, loader } = useHttp()
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const lang = localStorage.getItem('lang')

    const HandleAllUniversities = React.useCallback(async () => {
        try {
            const resUniversities = await request('/all-universities', 'post', { id_lan: lang === 'ru' ? 1 : 2 })
            const resRegion = await request('/regions', 'post', { id_lan: lang === 'ru' ? 1 : 2 })
            setAllUniversities(pre => pre = resUniversities.data)
            setDefaultUniversities(pre => pre = resUniversities.data)
            setUniversities(pre => pre = resUniversities.data)
            setRegions(pre => pre = resRegion)
            dispatch(countryAction(lang === 'ru' ? 1 : 2))
        } catch (e) {
        }
    }, [defaultUniversities, allUniversities, regions, lang])

    React.useEffect(() => {
        setWindowLoader(false)
        if (!lang) {
            i18n.changeLanguage('ru')
            localStorage.setItem('lang', 'ru')
            setCookie('lang', 'ru')
        }
    }, [])

    React.useEffect(() => {
        if (!windowLoader) {
            scrollTop()
        }
    }, [windowLoader])

    React.useEffect(() => {
        HandleAllUniversities()
    }, [lang])

    function scrollTop() {
        window.addEventListener('scroll', () => {
            if (window.scrollY >= 100) {
                return refUp.current.style.display = 'block'
            }
            refUp.current.style.display = 'none'
        })
    }

    function top() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    if (windowLoader) {
        return (
            <div style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
                <Loader />
            </div>
        )
    }

    return (
        <React.Fragment>
            <AppContext.Provider
                value={{
                    loader,
                    allUniversities,
                    setAllUniversities,
                    defaultUniversities,
                    regions,
                    universities,
                    setUniversities,
                    setActiveLinkNavbar,
                    setChangeSelect,
                    changeSelect
                }}>
                <Navbar
                    // changeLanguage={changeLanguage}
                    activeLinkNavbar={activeLinkNavbar}
                />
                <ToastContainer />
                <RouterPage />
                <Footer />
                <div
                    className="up"
                    onClick={top}
                    ref={refUp}
                >{t('up')}</div>
            </AppContext.Provider>
        </React.Fragment>
    );
})

export default App;